<template>
<div style="position:relative">
    <div v-if="!mobileView">
             <div class="zoom">
                <img src="@/assets/img/panel/bg1.png"
                style="width:100%" alt="not_found">
            </div>
     
            <div class= "center-left" >
            
                <div class="card special-card">
                    <img src="@/assets/img/icons/Panah.png" style="width:20%">
                    <div class="component">
                    <div class=" fontHeader">Nara Consulting <br>
                    Everything just got easie<br></div>
                    <div class="fontcontentHeader">Nara with experience and expertise in the field<br>
                        Financial Management, Tax Process and Business<br>
                        and consists of young professionals certified in<br>
                        finance and tax will be the solution for your sustainable business.
                    </div>
                    </div>
                </div>
            </div>
    </div>

        <div v-if="mobileView">
        
            <div class="card special-card">
                <img src="@/assets/img/icons/Panah.png" style="width:20%">
                <div class="component">
                <div class=" fontHeader">Nara Consulting <br>
                Everything just got easie<br></div>
                <div class="fontcontentHeader">Nara with experience and expertise in the field<br>
                    Financial Management, Tax Process and Business<br>
                    and consists of young professionals certified in<br>
                    finance and tax will be the solution for your sustainable business.
                </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
export default{
    data : () =>{
    return {
      mobileView :true,
      showNav :false
    };
  },
  methods: {
    handleView(){
      this.mobileView = window.innerWidth <= 990;
      window.addEventListener('resize', this.handleView);
    }
  },
   created(){
    this.handleView();
  }
}

</script>