<template>

<div class="container-fluid">
        <div class="row mt-5" id="hide">
            <div class="row" v-if="!mobileView">
                    <div class="col-5" id="aboutUs">
                        
                            <img src="@/assets/img/panel/About_us_vector.png"
                            class="zoom" style="margin-left: 86px; width: 75%;"
                            alt="not_found">
                        
                    </div>
                    <div class="col-7" >
                        <div class="mt-5 component fontWeight"> 
                        <div class="text-center">
                            <img src="@/assets/img/icons/Panah.png" style="width:10%">
                                
                                    <h1>About Us</h1>
                                    <hr>
                        </div>            
                                    <div class="font12 aboutText " style="padding-left: 42px;
                                    padding-right: 56px; position:relative; ">
                                        
                                    Significant ease in building a business in Indonesia
                                    has jumped 15
                                    times higher than in previous years. 
                                    The impact of many new
                                    companies (Start-up)
                                    emerging to compete in this country.
                                    Nara is here to be a part of this atmosphere and support 
                                    a sustainable business by becoming a strategic partner 
                                    for our clients in running and developing their business.
                                    Nara with his experience and expertise in the field of 
                                    Financial
                                    Management, Process and Taxation Business 
                                    and consists 
                                    of young finance and tax certified professionals
                                    will be a solution 
                                    for your sustainable business,
                                    </div>
                                
                    </div>
                    </div>
                
            </div> <!--row mobile-view -->

            <div v-if="mobileView">
                <div  id="aboutUs">
                        
                            <img src="@/assets/img/panel/About_us_vector.png"
                            class="zoom" style=" width: 100%;"
                            alt="not_found">
                        
                    </div>
                    <div class="" >
                        <div class="mt-5 component fontWeight"> 
                        <div class="text-center">
                            <img src="@/assets/img/icons/Panah.png" style="width:10%">
                                
                                <div class="fontHeaderBservice">About Us</div>
                                    <hr>
                        </div>            
                                    <div class="font12 aboutText " style="padding-left: 42px;
                                    padding-right: 56px; position:relative; ">
                                        
                                    Significant ease in building a business in Indonesia
                                    has jumped 15
                                    times higher than in previous years. 
                                    The impact of many new
                                    companies (Start-up)
                                    emerging to compete in this country.
                                    Nara is here to be a part of this atmosphere and support 
                                    a sustainable business by becoming a strategic partner 
                                    for our clients in running and developing their business.
                                    Nara with his experience and expertise in the field of 
                                    Financial
                                    Management, Process and Taxation Business 
                                    and consists 
                                    of young finance and tax certified professionals
                                    will be a solution 
                                    for your sustainable business,
                                    </div>
                                
                    </div>
                    </div>
            </div>
            
           
                 <div class="col-lg-12 margin-top10 " id="bussines">
                    <div class="text-center">
                        <img src="@/assets/img/icons/Panah.png" style="">
                        <div class="fontHeaderBservice">Bussines Services</div>
                        <hr>
                    </div>
                    <div v-if="!mobileView">
                                    <p class="fontBService"> 
                                    Explore how we can help you through our line of integrated services.
                                    </p>
                                
                                        <div class="groupImgBService">
                                            <div  @click="onRelax(this)" class="icons_temp"  style="margin-right:10em;">
                                                <img id="relax" ref="relax" src="@/assets/img/icons/relax.png"  alt="not_found"   style="width:100%" > 
                                                
                                            </div>
                                        
                                            <div  @click="onHealing(this)" class="icons_temp"  style="margin-right:10em;">
                                            <img id="healing" ref="healing"  src="@/assets/img/icons/healing.png" alt="not_found"  style=" width:100%">
                                            </div>

                                            <div  @click="onDetox(this)"  class="icons_temp"  style="margin-right:10em;">
                                                <img  id="detox" ref="detox"  src="@/assets/img/icons/detox.png" alt="not_found" style="width:100%">
                                            </div>            
                                        </div>
                                        
                                        <div class="groupImgBService">
                                            <div class="title_iconTemp fontWeight " v-if="relax === false" style="margin-left:-11px ; display:none"> 
                                            
                                            RELAX 
                                            </div>
                                            
                                            <div class=" fontWeight "  v-else-if="relax === true" style="margin-left:-11px ; "> 
                                            
                                            <div class=""  >
                                            <div class="title_iconTemp" style="margin-bottom:1.5em">RELAX</div>
                                                <div class="" style="margin-bottom:1em">
                                                    <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                    - Monthly package which include
                                                    </div>
                                                    <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                    - PPh 23 reporting
                                                    </div>
                                                </div>
                                                
                                                <div class="" style="margin-bottom:1em">
                                                    <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - Tidying and maintenance financial records
                                                    </div>
                                                    <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:4.5em"> 
                                                        - PPh 23 reporting(2)
                                                    </div>
                                                </div>

                                                <div class="" style="margin-bottom:1em">
                                                    <div class="fontWeight " style="font-size:20px;  display:inline-block;">
                                                    - Financial statements quarter
                                                    </div>
                                                    <div class="fontWeight " style="font-size:20px; display:inline-block;margin-left:11.2em"> 
                                                    - PPh 25 reporting
                                                    </div>
                                                </div>

                                                <div class="" style="margin-bottom:1em">
                                                    <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                    - Calculations and PPh 21 reporting
                                                    </div>
                                                    <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left: 8.5em;"> 
                                                    - VAT reporting
                                                    </div>
                                                </div>

                                                <div class="" style="margin-bottom:1em">
                                                    <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - Payment and BPJS reporting
                                                    </div>
                                                    <div class="fontWeight " style="font-size:20px; display:inline-block"> 
                                                    <!-- VAT reporting -->
                                                    </div>
                                                </div>
                                        </div>  
                                            </div>
                                            
                                            <div class="title_iconTemp fontWeight" v-if="healing === false" style="margin-left:-11px; display:none"> HEALING </div>
                                            
                                            <div class="fontWeight" v-else-if="healing === true" style="margin-left:-11px; display:inline-block">
                                                <div class="title_iconTemp" style="margin-bottom:1.5em">HEALING</div>

                                                Improvements to financial records including operational audits, inventory to taxation. 
                                            </div>
                                            
                                            <div class="title_iconTemp fontWeight" v-if="detox === false" style="margin-left:-11px; display:none"> DETOX </div>
                                            
                                            <div class=" fontWeight" v-else-if="detox === true" style="margin-left:-11px display:inline-block">
                                                <div class="title_iconTemp" style="margin-bottom:1.5em">DETOX</div>
                                                    <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - SP2DK
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:13.1em"> 
                                                        - PPh 21
                                                        </div>
                                                    </div>

                                                    <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - Tax Amnesty
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                        - Problem package tax settlement
                                                        </div>

                                                    </div>

                                                    <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        -  Payment and BPJS reporting
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                        <!-- - Problem package tax settlement -->
                                                        </div>
                                                    </div>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                        </div>

                                <div v-if="mobileView">
                                    <p class="text-center" style="margin-top:2em"> 
                                    Explore how we can help you through our line of integrated services.
                                    </p>
                                
                                        <div class="groupImgBService_moblie" style="width:100%">
                                            <div style="position:relative; left:6%">
                                                <div class="icon_temp_div">
                                                    <div  @click="onRelax(this)" class="icons_temp"   >
                                                    <img id="relax" ref="relax" src="@/assets/img/icons/relax.png"  alt="not_found"   style="width:100%" > 
                                                    
                                                </div>
                                                </div>
                                            
                                                <div class="icon_temp_div">
                                                    <div  @click="onHealing(this)" class="icons_temp" >
                                                <img id="healing" ref="healing"  src="@/assets/img/icons/healing.png" alt="not_found"  style=" width:100%">
                                                </div>
                                                </div>
                                                <div class="icon_temp_div">
                                                    
                                                <div  @click="onDetox(this)"  class="icons_temp"  >
                                                    <img  id="detox" ref="detox"  src="@/assets/img/icons/detox.png" alt="not_found" style="width:100%">
                                                </div>
                                                </div>  
                                            </div>          
                                        </div>
                                        
                                        <div class="groupImgBService_mobile">
                                            <div class="title_iconTemp fontWeight " v-if="relax === false" style="margin-left:-11px ; display:none"> 
                                            
                                            RELAX 
                                            </div>
                                            
                                            <div class=" fontWeight "  v-else-if="relax === true" style="margin-left:-11px ; "> 
                                            
                                            <div class=""  >
                                                    <div class="title_iconTemp" style="margin-bottom:1.5em">RELAX</div>
                                                        <!-- <div class="" style="margin-bottom:1em">
                                                            <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                            - Monthly package which include
                                                            </div>
                                                            <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                            - PPh 23 reporting
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="" style="margin-bottom:1em">
                                                            <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                                - Tidying and maintenance financial records
                                                            </div>
                                                            <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:4.5em"> 
                                                                - PPh 23 reporting(2)
                                                            </div>
                                                        </div>

                                                        <div class="" style="margin-bottom:1em">
                                                            <div class="fontWeight " style="font-size:20px;  display:inline-block;">
                                                            - Financial statements quarter
                                                            </div>
                                                            <div class="fontWeight " style="font-size:20px; display:inline-block;margin-left:11.2em"> 
                                                            - PPh 25 reporting
                                                            </div>
                                                        </div>

                                                        <div class="" style="margin-bottom:1em">
                                                            <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                            - Calculations and PPh 21 reporting
                                                            </div>
                                                            <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left: 8.5em;"> 
                                                            - VAT reporting
                                                            </div>
                                                        </div>

                                                        <div class="" style="margin-bottom:1em">
                                                            <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                                - Payment and BPJS reporting
                                                            </div>
                                                            <div class="fontWeight " style="font-size:20px; display:inline-block"> 
                                                           
                                                            </div>
                                                        </div> -->
                                                </div>  
                                            </div>
                                            
                                            <div class="title_iconTemp fontWeight" v-if="healing === false" style="margin-left:-11px; display:none"> HEALING </div>
                                            
                                            <div class="fontWeight" v-else-if="healing === true" style="margin-left:-11px; display:inline-block">
                                                <div class="title_iconTemp" style="margin-bottom:1.5em">HEALING</div>

                                                Improvements to financial records including operational audits, inventory to taxation. 
                                            </div>
                                            
                                            <div class="title_iconTemp fontWeight" v-if="detox === false" style="margin-left:-11px; display:none"> DETOX </div>
                                            
                                            <div class=" fontWeight" v-else-if="detox === true" style="margin-left:-11px display:inline-block">
                                                <div class="title_iconTemp" style="margin-bottom:1.5em">DETOX</div>
                                                    <!-- <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - SP2DK
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:13.1em"> 
                                                        - PPh 21
                                                        </div>
                                                    </div>

                                                    <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        - Tax Amnesty
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                        - Problem package tax settlement
                                                        </div>

                                                    </div>

                                                    <div class="" style="margin-bottom:1em">

                                                        <div class="fontWeight " style="font-size:20px;  display:inline-block">
                                                        -  Payment and BPJS reporting
                                                        </div>
                                                        <div class="fontWeight " style="font-size:20px; display:inline-block; margin-left:10em"> 
                                                        </div>
                                                    </div> -->
                                            </div>
                                        </div>
                        </div>
           </div>

            <div class="col-lg-12 margin-top10" id="companyVal">
                 <div v-if="!mobileView">
                    <div class="text-center">
                    <img src="@/assets/img/icons/Panah.png" style="">
                    <div class="fontHeaderBservice"> Company Value </div>
                        <p class="content_center fontWeight"> 
                            Become a "HOME" or a comfortable place
                            for all stakeholders and a comfortable
                            place to find solutions 
                            to various problems related to self-
                            development and business sustainable for all stakeholders.

                        </p>   
                        <div class="fontCPV" style="position:relative;margin-top:2em;width:991px">
                           <div style="position: relative; display: inline-block; right: 64px;"> Rasa</div>
                           <div style="position: relative; display: inline-block;right: -15px;"> Unifikasi</div>
                           <div style="position: relative; display: inline-block; right: -105px; "> Mimpi</div>
                           <div style="position: relative; display: inline-block; left: 220px;"> Amanah </div>
                            <div style="position: relative; display: inline-block; left: 335px;"> Hilir </div>
                        </div>

                     </div>
                 </div>
                 <div v-if="mobileView">
                    <div class="text-center">
                    <img src="@/assets/img/icons/Panah.png" style="">
                    <div class="fontHeaderBservice"> Company Value </div>
                    <hr>
                    </div>
                        <p class="fontWeight" style="font-size:14px; margin-top: 2em"> 
                            Become a "HOME" or a comfortable place
                            for all stakeholders and a comfortable
                            place to find solutions 
                            to various problems related to self-
                            development and business sustainable for all stakeholders.

                        </p>   
                        <!-- <div class="fontCPV" style="position:relative;margin-top:2em;width:991px">
                           <div style="position: relative; display: inline-block; right: 64px;"> Rasa</div>
                           <div style="position: relative; display: inline-block;right: -15px;"> Unifikasi</div>
                           <div style="position: relative; display: inline-block; right: -105px; "> Mimpi</div>
                           <div style="position: relative; display: inline-block; left: 220px;"> Amanah </div>
                            <div style="position: relative; display: inline-block; left: 335px;"> Hilir </div>
                        </div> -->

                     </div>
                 
            
             </div>

             <div class="col-lg-12 margin-top10" >
                <div class="text-center">
                    <!-- <img src="@/assets/img/icons/Panah.png" style=""> -->
                    <div class="fontHeaderBservice"> Our Loyal Client </div>
                    <div v-if="!mobileView">
                        
                    <img src="@/assets/img/partnership/Group_9.png" style="">
                    </div>
                </div> 
             </div>

            <div class="col-lg-12 margin-top10" >
                <div class="text-center" style="height: 300px;">
                    <!-- <img src="@/assets/img/icons/Panah.png" style=""> -->
                    <div class="fontHeaderBservice"> partnership </div>
                    <div v-if="!mobileView">
                    <img src="@/assets/img/partnership/Group_19.png" style="">
                    </div>
                </div> 
             </div>

        </div>
</div>
</template>
<script>
 export default{
    name : "busines_service",
    data(){
        return{
            relax:true,
            healing:false,
            detox: false,
            mobileView: true
        }
        
    }, 
    methods: {
        onRelax(){
        
            this.relax = true;
            this.healing = false;
            this.detox =false;
        
        },
        onHealing(){
            this.relax = false;
            this.healing = true;
            this.detox =false;
        },
        onDetox(){
            this.relax = false;
            this.healing = false;
            this.detox =true;
        },
        handleView(){
        this.mobileView = window.innerWidth <= 990;
        window.addEventListener('resize', this.handleView);
        }
        },
        created(){
            this.handleView();
        }
 }
</script>