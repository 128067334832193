
import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue'
import tes1 from '../layout/ind/indContent.vue'
import login from '../components/login.vue'
import profile from '../components/profile.vue'
import indRelax from '../views/componentViews/ind/detailRelax.vue'
import indDetox from '../views/componentViews/ind/detailDetox.vue'
import indHealing from '../views/componentViews/ind/detailHealing.vue'
const routes = [
  {
    path: '/',
    redirect: "/ind/",
    name: 'Home',
    component: Home,
    meta: {hideheader : false}
  },
  {
    path: '/ind/',
    name: 'tes',
    component: tes1,
    meta : {hideheader : false} 
  },
  
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {hideheader : true}
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {hideheader : true}
  },
  {
    path: '/ind/relax',
    name: 'detRelax',
    component: indRelax,
    meta : {hideheader : false}
  },
  {
    path: '/ind/detox',
    name: 'detDetox',
    component: indDetox,
    meta : {hideheader : false}
  },
  {
    path: '/ind/healing',
    name: 'detHealing',
    component: indHealing,
    meta : {hideheader : false}
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

// module.exports = {
//   publicPath: "/test/dist"
// }

export default router
