<template>
    <div class="container-fluid">
        <div class="" style="margin-top:10em;position:relative">
             <img src="@/assets/img/icons/Panah.png" style="">
                    <div class="fontHeaderBservice">RELAX</div>

                <div class="" style="margin-top:5em">
                    <div class="zoom" style="display:inline-block; width:50% ; height: 500px">
                        <img src="@/assets/img/panel/mask.png"
                        alt="not_found">
                    </div> 
                    <div class="detailsProd"  >
                         <div style="width:100%">
                         <img src="@/assets/img/icons/Panah.png" style="margin:1.2em ">
                         </div>
                         <div class="" style="width:50%; display:inline-block;padding:5px;position:relative">
                           <div class="fontWeight" style="font-size:20px"> Monthly package
                            which include
                            </div>
                            <div class="fontWeight posAbsolute" style="top:65px; font-size:20px"> Tidying and maintenance <br>
                                financial records
                            </div>

                             <div class="fontWeight posAbsolute" style="top:130px;font-size:20px">
                            Financial statements
                            quarter
                            </div>

                            <div class="fontWeight posAbsolute" style="top:175px; font-size:20px">
                            Calculations and PPh 21 reporting
                            </div>

                            <div class="fontWeight posAbsolute" style="top:250px; font-size:20px">
                            Payment and BPJS reporting
                            </div>  
                         </div>
                         <div class="" style="width:50%; display:inline-block;padding:5px; position:relative" >
                            <div class="fontWeight posAbsolute" style="font-size:20px;top:-30px"> 
                            PPh 23 reporting
                            </div>

                            <div class="fontWeight posAbsolute" style="top:30px; font-size:20px">
                            PPh 4 Reporting (2)
                                
                            </div>

                            <div class="fontWeight posAbsolute" style="top:95px;font-size:20px">
                            PPh 25 reporting
                            </div>

                            <div class="fontWeight posAbsolute" style="top:145px; font-size:20px">
                            VAT reporting
                            </div>
                            
                         </div> 
                    </div> 
                </div>     
        </div>
    </div>
</template>