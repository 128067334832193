<template>
  <div class="mw-100%">
    <div id="navigation-icon" v-if="mobileView">
      <nav class="navbar navbar-light">
    
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <img class="mobile-icon-nav" src="@/assets/img/LOGO_NARA.png">
      </nav>
    </div>
    <!-- <indNavbar1 v-if="!$route.meta.hideheader"></indNavbar1> -->
     <indNavbar v-if="!$route.meta.hideheader && !mobileView"></indNavbar>
    <!-- <indContent></indContent> -->
    <router-view/>
    <footerVue v-if="!$route.meta.hideheader && !mobileView" ></footerVue>
    

    
    
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import headerVue from './layout/header.vue'
// import indNavbar1 from './layout/ind/indNavbar1.vue'
import indNavbar from './layout/ind/indNavbar.vue'
// import indContent from './layout/ind/indContent.vue'
import footerVue from './layout/footer.vue'
// import store from "@/store"
export default {
   data : () =>{
    return {
      mobileView :true,
      showNav :false
    };
  },
  methods: {
    handleView(){
      this.mobileView = window.innerWidth <= 990;
      window.addEventListener('resize', this.handleView);
    }
  },

  components: {
    indNavbar,
    footerVue
  },
  created(){
    this.handleView();
  }
}
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/style/main.scss";
</style>

