<template>
<div>
<indHeader></indHeader>
<indContent></indContent>
</div>
</template>

<script>
// @ is an alias to /src

// import home from '../views/componentViews/homeCompo.vue'
import indHeader from '@/views/componentViews/ind/indHeader.vue'
import indContent from '@/views/componentViews/ind/indMainContent.vue'
export default {
  name: 'Content',
  components: {
    indHeader,
    indContent
  }
}
</script>