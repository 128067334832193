<template>
    <div class="container-fluid">
        <div class="" style="margin-top:10em;position:relative">
             <img src="@/assets/img/icons/Panah.png" style="">
                    <div class="fontHeaderBservice">HEALING</div>

                <div class="" style="margin-top:5em">
                    <div class="zoom" style="display:inline-block; width:50% ; height: 500px">
                        <img src="@/assets/img/panel/Mask_detox.png"
                        alt="not_found">
                    </div> 
                    <div class="detailsProd"  >
                         <div style="width:100%">
                         <img src="@/assets/img/icons/Panah.png" style="margin:1.2em ">
                         </div>
                         <div class="" style="width:100%; padding:5px;position:relative">
                           <div class="fontWeight" style="font-size:20px"> 
                            Improvements to financial records
                            including
                            operational audits, inventory to taxation.
                            </div>
                              
                         </div>
                         
                    </div> 
                </div>     
        </div>
    </div>
</template>