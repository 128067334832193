// import Vue from 'vue'
// import Vuex from 'vuex'
import { createStore } from 'vuex'
import {auth}  from "./auth.module";

// Vue.use(Vuex)

const store = createStore({
  modules: {
    auth,
  },
});

export default store;

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
