<template>
    <div>
        <nav class="navbar2  ">
            <div class="center">
                <!-- <div class="d-flex justify-content-start " style="display:inline-block; position:absolute">
                    
                </div> -->
                <div class="d-flex justify-content-center ">
                <img class="content" src="@/assets/img/LOGO_NARA.png">
                <div style="top:0.8em; position:relative">
                    <ul>
                        <li><a href="/ind/#Home" class="content"> Home </a></li>
                        <li><a href="/ind/#aboutUs" class="content"> About Us </a></li>
                        <li><a href="/ind/#bussines" class="content"> Bussines Services </a></li>
                        <li><a href="/ind/#companyVal" class="content"> Company Value </a></li>
                    </ul>

                        <!-- <a href="" class="content"> Career </a> -->
                </div>
                </div>
            </div>
        </nav>
    </div>
</template>