<template>
  <div class="container">
    <!-- <header class="jumbotron"> -->
      <h3>
        <strong>{{currentUser.username}}</strong> Profile
      </h3>
    <!-- </header> -->
    <!-- <p>
      <strong>Token:</strong>
      {{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}}
    </p> -->
    <!-- <p>
      <strong>Id:</strong>
      {{currentUser.id}}
    </p>
    <p>
      <strong>Email:</strong>
      {{currentUser.email}}
    </p>
    <strong>Authorities:</strong>
    <ul>
      <li v-for="role in currentUser.roles" :key="role">{{role}}</li>
    </ul> -->
  </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode';
export default {
  
  
  name: 'Profile',
  computed: {
    currentUser() {
      // const decoded = decode(this.$store.state.auth.user.accessToken);  
    
// var userId = decoded.
try{
  let token = localStorage.getItem('user');
      console.log(token);
        console.log(VueJwtDecode.decode(token))
        // current_user = decoded;
    }
    catch(err){
        console.log('token is null: ',err);
    }
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>