<template>
    <div class="container-fluid">
        <div class="" style="margin-top:10em;position:relative">
             <img src="@/assets/img/icons/Panah.png" style="">
                    <div class="fontHeaderBservice">DETOX</div>

                <div class="" style="margin-top:5em">
                    <div class="zoom" style="display:inline-block; width:50% ; height: 500px">
                        <img src="@/assets/img/panel/Mask_detox.png"
                        alt="not_found">
                    </div> 
                    <div class="detailsProd"  >
                         <div style="width:100%">
                         <img src="@/assets/img/icons/Panah.png" style="margin:1.2em ">
                         </div>
                         <div class="" style="width:100%; padding:5px;position:relative">
                           <div class="fontWeight" style="font-size:20px"> 
                                SP2DK
                            </div>
                            <div class="fontWeight posAbsolute" style="top:65px; font-size:20px"> 
                                 PPh 21
                            </div>

                             <div class="fontWeight posAbsolute" style="top:130px;font-size:20px">
                                Tax Amnesty
                            </div>

                            <div class="fontWeight posAbsolute" style="top:190px; font-size:20px">
                                Problem package tax settlement
                            </div>

                            <div class="fontWeight posAbsolute" style="top:250px; font-size:20px">
                            Payment and BPJS reporting
                            </div>  
                         </div>
                         
                    </div> 
                </div>     
        </div>
    </div>
</template>