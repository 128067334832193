<template>
  <!-- <div class="container"> -->
    <footer>
      <div class="  text-white "  >
        <div class="row">
          <div class=" col-12">
            <div class="desktop-only">
                <div class="group-text-img">
                     <h1>  <img src="@/assets/img/LOGO_NARA.png" style="" alt="not_found"><p>Nara Consulting</p></h1>
                </div>
                    <div class="m-4">
                      <h2><p >CONTANCT</p></h2>
                        <div class="group-text-img">
                          <h4> <img src="../assets/img/icons/message.png"  alt="not_found"><p class= "font-fotter">Email : nadia.ramadhania.@narasolutions.id</p></h4>
                        </div> 
                        <div class="group-text-img">
                          <h4> <img src="../assets/img/icons/phone.png"  alt="not_found"><p class= "font-fotter">Phone 021 - 7945301</p></h4>
                        </div> 
                        <div class="group-text-img">
                          <h4> <img src="@/assets/img/icons/whatsapp.png"  alt="not_found"><p class= "font-fotter">Konsultasi Online 0856 9229 4207</p></h4>
                      </div> 
                  
                      <div class= "group-logo">
                        <img src="@/assets/img/icons/Group.png"   alt="not_found">
                      
                      </div> 
                
                  
                </div>
              </div>
          </div>
          <div class="col-12">
            
       <div class="widh600">
                <!-- <h1>  <img src="./assets/img/LOGO NARA 1 -01 1.png" style="" alt="not_found"><p>Nara Consulting</p></h1> -->
              
              <div class="m-4">
                <h2><p >CONTANCT</p></h2>
              
                    <!-- <h4> <img src="./assets/img/icons/message.png"  alt="not_found"><p class= "font-fotter">Email : nadia.ramadhania.@narasolutions.id</p></h4> -->
               
              
                    <!-- <h4> <img src="./assets/img/icons/phone.png"  alt="not_found"><p class= "font-fotter">Phone 021 - 7945301</p></h4> -->
               
              
                    <!-- <h4> <img src="./assets/img/icons/whatsapp.png"  alt="not_found"><p class= "font-fotter">Konsultasi Online 0856 9229 4207</p></h4> -->
                          
                  <div class="group-icon">
                  <!-- <img src="./assets/img/icons/Group 26.png"   alt="not_found"> -->
                  </div>
                  
           
            
          </div>
       </div>
          </div>
          
        
        </div>
          
      </div>  
    </footer>
      <!-- </div> -->
  




</template>